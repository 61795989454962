<template>
	<div>
		<is-loading
			v-if="!createNewUser"
			:loading="loading"
			:has-data="hasData"
			:loading-label="translate('loading')"
			:no-data-label="translate('user_not_found')" />
		<b-form
			v-if="showForm"
			@submit.prevent="userInfoSubmit"
			@keydown="clearError($event.target.name);"
			@change="clearError($event.target.name);">
			<input-text
				id="username"
				:label="translate('username')"
				:setter-value="form.username"
				:errors="errors['username']"
				:required="true"
				type="text"
				@dataChanged="form.username = $event" />

			<div
				v-if="form.type === distributor"
				class="form-group">
				<label
					for="type"
					class="label">{{ translate('type') }}
					<span class="text-danger">*</span></label>
				<select
					id="type"
					v-model="form.distributor_type"
					name="type"
					:class="hasError('distributor_type') ? 'is-invalid' : ''"
					class="form-control">
					<option
						v-for="type in distributorTypes"
						:key="type"
						selected
						:value="type">
						{{ translate(type) }}
					</option>
				</select>
				<template v-if="hasError('distributor_type')">
					<span
						v-for="error in errors['distributor_type']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<div
				v-if="form.type === distributor"
				class="form-group">
				<search
					v-model="form.sponsor_username"
					:label-text="translate('sponsor_username')"
					:endpoint="endpoint"
					required
					:empty-result-message="translate('data_not_found')"
					:default-params="{role:'distributor,agency'}"
					:custom-error="hasError('sponsor_username') ? errors['sponsor_username'][0] : false"
					q="label"
					return-key="username"
					@input="clearError('sponsor_username')" />
			</div>

			<div
				v-if="form.type === distributor"
				class="form-group">
				<search
					v-model="form.parent_username"
					:label-text="translate('parent_username')"
					:endpoint="endpoint"
					:empty-result-message="translate('data_not_found')"
					:default-params="{role:'distributor,agency'}"
					:custom-error="hasError('parent_username') ? errors['parent_username'][0] : false"
					q="label"
					return-key="username"
					@input="clearError('parent_username')" />
			</div>

			<div
				v-if="form.type === distributor"
				class="form-group">
				<label
					for="leg"
					class="label">{{ translate('leg') }}
					<span class="text-danger">*</span></label>
				<select
					id="leg"
					v-model="form.leg"
					name="leg"
					:class="hasError('leg') ? 'is-invalid' : ''"
					class="form-control">
					<option
						v-for="leg in legNames"
						:key="leg"
						selected
						:value="leg">
						{{ translate(leg) }}
					</option>
				</select>
				<template v-if="hasError('leg')">
					<span
						v-for="error in errors['leg']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<input-text
				id="email"
				:label="translate('email')"
				:setter-value="form.email"
				:errors="errors['email']"
				:required="true"
				type="text"
				@dataChanged="form.email = $event" />

			<input-text
				id="first_name"
				:label="translate('first_name')"
				:setter-value="form.first_name"
				:errors="errors['first_name']"
				:required="true"
				type="text"
				@dataChanged="form.first_name = $event" />

			<input-text
				id="last_name"
				:label="translate('last_name')"
				:setter-value="form.last_name"
				:errors="errors['last_name']"
				:required="true"
				type="text"
				@dataChanged="form.last_name = $event" />

			<div
				v-if="!createNewUser"
				class="form-group">
				<label for="two_factor_auth">
					{{ translate('two_factor_auth') }}
				</label>
				<switch-toggle
					id="two_factor_auth"
					:disabled="!form.tfa_enabled"
					:checked="!!form.tfa_enabled"
					name="two_factor_auth"
					variant="success"
					class="d-block mt-2"
					no-margin
					pill
					@change="form.tfa_enabled = !form.tfa_enabled" />
			</div>

			<div
				v-if="countries !== null"
				class="form-group">
				<label
					for="country"
					class="label">{{ translate('country') }}
					<span class="text-danger">*</span></label>
				<select
					id="country"
					v-model="form.country"
					:class="hasError('country') ? 'is-invalid' : ''"
					:disabled="!countries.length"
					type="text"
					name="country"
					class="form-control">
					<option
						v-if="form.type === corporate"
						:value="null">
						{{ translate('all') }}
					</option>
					<option
						v-for="country in countries"
						:key="country.attributes.code"
						:value="country.attributes.code">
						{{ translate(country.attributes.code.toLowerCase()) }}
					</option>
				</select>
				<template v-if="hasError('country')">
					<span
						v-for="error in errors['country']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<div class="form-group">
				<label
					for="mobile_number"
					class="label">
					{{ translate('mobile_number') }}
					<span
						v-if="form.type === distributor"
						class="text-danger">*</span>
				</label>
				<vue-tel-input
					id="mobile_number"
					v-model="form.mobile_number"
					:placeholder="translate('enter_phone_number')"
					:class="hasError('mobile_number') ? 'is-invalid' : ''"
					v-bind="bindProps"
					name="mobile_number"
					class="form-control"
					@onInput="handleMobileChange" />
				<template v-if="hasError('mobile_number')">
					<span
						v-for="error in errors['mobile_number']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<div
				v-if="form.type !== distributor"
				class="form-group">
				<label
					for="status"
					class="label">{{ translate('status') }}
					<span class="text-danger">*</span></label>
				<select
					id="status"
					v-model="form.status"
					:class="hasError('status') ? 'is-invalid' : ''"
					:disabled="!Object.keys(statuses).length > 0 ? true : false"
					type="text"
					name="status"
					class="form-control">
					<option
						v-if="!Object.keys(statuses).length > 0 ? true : false"
						value="">
						{{ translate('loading') }}
					</option>
					<option
						v-for="status in statuses"
						:key="status"
						:value="status">
						{{ translate(status) }}
					</option>
				</select>
				<template v-if="hasError('status')">
					<span
						v-for="error in errors['status']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<input-text
				v-if="form.type === distributor"
				id="company"
				:label="translate('company')"
				:setter-value="form.company"
				:errors="errors['company']"
				:required="false"
				type="text"
				@dataChanged="form.company = $event" />

			<input-text
				v-if="form.type === distributor && !form.company"
				id="national_identification_number"
				:label="translate(`${userCountry}`)"
				:setter-value="form.national_identification_number"
				:errors="errors['national_identification_number']"
				type="text"
				@dataChanged="form.national_identification_number = $event" />

			<div
				v-if="form.type === corporate && ['xs', 'sm'].includes(windowWidth) && Object.keys(accessGroups).length > 0"
				class="form-group">
				<label
					for="access_group"
					class="label">{{ translate('access_group') }}</label>
				<select
					id="access_group"
					v-model="form.access_group"
					:class="hasError('access_group') ? 'is-invalid' : ''"
					type="text"
					name="access_group"
					class="form-control">
					<option
						v-for="accessGroup in accessGroups"
						:key="accessGroup"
						:value="accessGroup">
						{{ translate(accessGroup) }}
					</option>
				</select><br>
				<b-alert
					variant="secondary"
					show>
					<b>{{ translate(form.access_group) }}</b>
					{{ translate(`${form.access_group}_description`) }}
				</b-alert>
				<template v-if="hasError('access_group')">
					<span
						v-for="error in errors['access_group']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<div
				v-if="form.type === corporate && !['xs', 'sm'].includes(windowWidth) && Object.keys(accessGroups).length > 0">
				<label
					for="access_group"
					class="label">{{ translate('access_group') }}</label>
				<b-row class="form-input">
					<b-col
						v-for="(accessGroup, key) in accessGroups"
						:key="key"
						:md="4"
						:xl="3">
						<b-card
							:bg-variant="accessGroup === form.access_group ? 'primary' : ''"
							:class="accessGroup === form.access_group ? 'selected' : ''"
							class="h-100"
							header-tag="header"
							@click="form.access_group = accessGroup">
							<template
								v-slot:header
								class="mb-5">
								<h6
									:class="accessGroup === form.access_group ? 'font-weight-bold' : ''"
									class="mb-0">
									{{ translate(accessGroup) }}
								</h6>
							</template>
							<b-card-text>{{ translate(`${accessGroup}_description`) }}</b-card-text>
						</b-card>
					</b-col>
				</b-row>
				<template v-if="hasError('access_group')">
					<span
						v-for="error in errors['access_group']"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</div>

			<input-text
				v-if="createNewUser"
				id="password"
				:label="translate('password')"
				:setter-value="form.password"
				:errors="errors['password']"
				:required="true"
				type="password"
				@dataChanged="form.password = $event" />

			<input-text
				v-if="createNewUser"
				id="password_confirmation"
				:label="translate('password_confirmation')"
				:setter-value="form.password_confirmation"
				:errors="errors['password_confirmation']"
				:required="true"
				type="password"
				@dataChanged="form.password_confirmation = $event" />

			<button
				:disabled="Object.keys(errors).length > 0"
				class="btn btn-primary float-right">
				{{ translate('submit') }}
			</button>
		</b-form>
	</div>
</template>
<script>

import { VueTelInput } from 'vue-tel-input';
import InputText from '@/components/InputText';
import SwitchToggle from '@/components/Switch';
import { TREE_LEG } from '@/settings/Invitations';
import {
	Profile, Users, AccessGroups, Countries, NIN,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import {
	corporate,
	admin,
	distributor,
	CREATE_DISTRIBUTOR_TYPES,
} from '@/settings/Roles';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';
import { SEARCH_USERS } from '@/config/endpoint';

export default {
	name: 'UserDataForm',
	messages: [Profile, Users, AccessGroups, Countries, NIN],
	components: {
		VueTelInput,
		InputText,
		SwitchToggle,
	},
	mixins: [WindowSizes],
	props: {
		createNewUser: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		userInformation: {
			type: Object,
			default() {
				return {};
			},
		},
		statuses: {
			type: Object,
			default() {
				return {};
			},
		},
		accessGroups: {
			type: Object,
			default() {
				return {};
			},
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		countries: {
			type: Array,
			default() {
				return null;
			},
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			corporate,
			availableCountries,
			admin,
			distributor,
			endpoint: SEARCH_USERS.endpoint,
			legNames: TREE_LEG,
			distributorTypes: CREATE_DISTRIBUTOR_TYPES,
		};
	},
	computed: {
		showForm() {
			return this.createNewUser || (!this.createNewUser && !this.loading && this.hasData);
		},
		hasData() {
			return Object.values(this.userInformation).length > 0;
		},
		userCountry() {
			if (this.form.country) {
				return `nin_${this.form.country.toLowerCase()}`;
			}
			return 'national_identification_number';
		},
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
		userInformation() {
			this.fetchFormData();
		},
	},
	created() {
		let displayCountries = [this.country];
		if (admin.includes(this.$user.details().type)) {
			displayCountries = this.availableCountries;
		}
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = displayCountries;
	},
	methods: {
		userInfoSubmit() {
			this.$emit('requestData', this.form);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		fetchFormData() {
			Object.keys(this.form).forEach((keyValue) => {
				this.form[keyValue] = this.userInformation[keyValue];
			});
		},
		handleMobileChange() {
			this.clearError('mobile_number');
		},
	},
};
</script>

<style scoped>
	.card:hover {
		cursor: pointer;
	}
	.card.selected > header {
		border-bottom: none;
		background-color: inherit !important;
	}
	.card.selected {
		box-shadow: 0px 0px 15px 7px #d2d2d28f;
	}
	.row.form-input > div {
		margin-bottom: 15px;
	}
</style>
